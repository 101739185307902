import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import {LoginRoutingModule} from './login-routing.module'
import {LoginComponent} from './login.component';
import { RouterModule, Routes } from "@angular/router";
import {HeadParInterceptor} from "../../../../core/interceptors/header-interceptor";
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

const routes: Routes = [
  { path: "", component: LoginComponent},

];
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule
  ],
  declarations: [LoginComponent],
  exports: [RouterModule],
 
})
export class LoginModule { }
