import { Injectable } from "@angular/core";
import { tap, map, catchError } from "rxjs/operators";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse, HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { of } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
// import { Observable } from "rxjs/Observable";
import { NgxSpinnerService } from 'ngx-spinner';



@Injectable()
export class DataService {
    constructor(private http: HttpClient, public ngxSpinnerService: NgxSpinnerService) {
        //alert('date service')
    }

    get<T>(url: string): Observable<T> {
        this.ngxSpinnerService.show();
        setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.ngxSpinnerService.hide();
        }, 2000);
        return this.http.get<T>(url);
    }

    post<T>(url: string, body: any): Observable<T> {
        this.ngxSpinnerService.show();
        setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.ngxSpinnerService.hide();
        }, 2000);
        return this.http.post<T>(url, body);
    }

    put<T>(url: string, body: string): Observable<T> {
        this.ngxSpinnerService.show();
        setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.ngxSpinnerService.hide();
        }, 2000);
        return this.http.put<T>(url, body);
    }

    delete<T>(url: string): Observable<T> {
        this.ngxSpinnerService.show();
        setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.ngxSpinnerService.hide();
        }, 2000);
        return this.http.delete<T>(url);
    }

    patch<T>(url: string, body: any): Observable<T> {
        this.ngxSpinnerService.show();
        setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.ngxSpinnerService.hide();
        }, 2000);
        return this.http.patch<T>(url, body);
    }
}


@Injectable()
export class HeadParInterceptor implements HttpInterceptor {
    constructor(private toast: ToastrService, private router: Router, public ngxSpinnerService: NgxSpinnerService) {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (localStorage.getItem('GreenliteAdminToken')) {
            
            const updatedRequest = request.clone({
                setHeaders: {
                    "apiType": "2",
                    "Authorization": "Bearer " + localStorage.getItem('GreenliteAdminToken'),
                    "Content-Type": "application/json"
                }

            });
            return next.handle(updatedRequest).pipe(catchError((error, caught) => {
                this.handleAuthError(error);
                return of(error);
            }) as any);
        }
        else {
            const updatedRequest = request.clone({
                headers: request.headers.set("apiType", "2"),

            });
            return next.handle(updatedRequest).pipe(catchError((error, caught) => {
                console.log(error);
                this.handleAuthError(error);
                return of(error);
            }) as any);;
        }


    }

    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        this.ngxSpinnerService.hide();
        this.toast.clear();
        //handle your auth error or rethrow
        if (err.status === 401) {
            console.log('handled error ' + err.status);
            this.toast.error(err.error.message, null, {
                timeOut: 10000
              });
            localStorage.clear();
            this.router.navigateByUrl('/')
            return of(err.message);
        } else {
            // This condition is so that we do not show error message in Family Group
            if (err.error.status !== -1) {
                if (err.error.message) {
                    this.toast.error(err.error.message, null, {
                        timeOut: 10000
                      });
                }
            }
        }
        throw err;
    }
}