import { BehaviorSubject, Observable, Subject, from, throwError } from 'rxjs';
import { map, catchError, tap, switchMap, finalize } from 'rxjs/operators';
import {AuthGuard} from './auth.guard'
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()

export class AuthenticationService {

	isBtnDisabled : boolean = false;
	constructor(private http: HttpClient, public ngxSpinnerService:NgxSpinnerService){

		//alert('auth service');
	}
	

    public login(url,username,password): Observable<any> 
    {   
		this.isBtnDisabled=true;
		let credentials:object = {
			'email' :username,
			'password':password,
			'deviceId':"",
			'deviceType':"2"
		} 
		this.ngxSpinnerService.show();
		return this.http.post(url,credentials).pipe( 
			map((result: any) => {
				return result;
			}
		),finalize(()=>{
			this.isBtnDisabled=false;
		})
			// catchError(this.handleError('login', []))
		);
    } 


	public forgotPassword(url,email): Observable<any> 
    { 
		let credentials:object = {
			'email' :email,
		} 
		this.ngxSpinnerService.show();
		return this.http.post(url,credentials).pipe( 
			map((result: any) => {
				this.ngxSpinnerService.hide();
				return result;
			}),
			// catchError(this.handleError('login', []))
		);
	}
	

	public register(url,username,password,cpass): Observable<any> 
    {  
		let credentials:object = {
			'first_name':"",
			'last_name':"",
			'email' :username,
			'password':password,
			'password_confirmation':cpass,
			'deviceId':"",
			'deviceType':"2"
		} 
		this.ngxSpinnerService.show();
		return this.http.post(url,credentials).pipe( 
			map((result: any) => {
				this.ngxSpinnerService.hide();

				// console.log("register",result);
				return result;
			}),
			// catchError(this.handleError('login', []))
		);
	}
	
	public studiosetup(url,response): Observable<any> 
    {  		
		// console.log("auth service enters ",response);
		let credentials:object = {
			"studio":{
				"name":response.bussiness,
				"address":response.add,
				"city":response.city,
				"state":response.state,
				"zip":response.zip,
				"phone":response.bno,
				"waiver_text_policy":response.policy,
				"green_lite_message":response.fireworkmessage,
			},
			'first_name':response.fname,
			'last_name':response.lname,
			'email':response.email,
			'phone':response.phone,
			'pin':response.pinnumber,
			'setup':response.setup,
			'cc_no':response.cardnumber,
			'name_on_card':response.nameoncard,
			'expiry_date':response.expirydate,
			'cvc':response.cvcnumber
		}  
		 
		// console.log(credentials);
		this.ngxSpinnerService.show();
		return this.http.post(url,credentials).pipe( 
			map((result: any) => { 
				this.ngxSpinnerService.hide();

				// console.log("studiosetup",result);
				return result;
			}),
			// catchError(this.handleError('login', []))
		);
	}
	

	public resetPassword(url,password,cpass,token,email): Observable<any> 
    {  
		let credentials:object = {
			'email':email,
			'password':password,
			'password_confirmation':cpass,
			'token':token
		} 
		this.ngxSpinnerService.show();
		return this.http.post(url,credentials).pipe( 
			map((result: any) => { 
				return result;
			}),
		);
	}
	public getStudio(url):Observable<any>
	{
		this.ngxSpinnerService.show();
		return this.http.get(url).pipe(
			map((result:any)=>{
				this.ngxSpinnerService.hide();
				return result;
			})
		)
	}
    
	public getStudiostatus(url):Observable<any>
	{
		return this.http.get(url).pipe(
			map((result:any)=>{
				return result;
			})
		)
	}
    
}