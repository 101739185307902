import { Injectable } from '@angular/core';
import { DataService } from 'src/app/core/interceptors/header-interceptor';
import { environment } from 'src/environments/environment.prod';

@Injectable({ providedIn: 'root' })
export class ClassesService {
  classes: any;
  constructor(private _dataService: DataService) {
    //alert('service called');
  }

  // Classes with Timings
  getClasses(studioId) {
    return this._dataService.get(environment.BASE_URL + `day_wise_class_timings`)
  }

  // Class List
  getClassList(studioId) {
    return this._dataService.get(environment.BASE_URL + `classes`);
  }

  // Options List
  getOptionsList() {
    return this._dataService.get(environment.BASE_URL + `class_frequencies`);
  }

  // Add Class
  postClass(data) {
    return this._dataService.post(environment.BASE_URL + `classes`, data);
  }

  // Edit Class
  postEditClass(data) {
    return this._dataService.patch(environment.BASE_URL + `class_timings`, data);
  }

  // Delete Class
  deleteClassTimings(id) {
    return this._dataService.delete(environment.BASE_URL + `class_timings/${id}`);
  }

  // Find Duplicate
  findDuplicate(start_date, start_time, class_name) {
    return this._dataService.get(environment.BASE_URL + `find_duplicate_class_timings?start_date=${start_date}&start_time=${start_time}&class_name=${class_name}`)
  }
}

