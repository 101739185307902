import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard, AuthGuardStudioId } from "../app/core/auth/auth.guard";
import { LogoutGuardService } from "../app/core/auth/logout-guard.service";
import { PagesModule } from '../app/content/pages/pages.module';
import { LoginModule } from './content/pages/auth/login/login.module'
import {} from './content/pages/components/mobile-page-error/mobile-page-error.module'

const routes: Routes = [
  {
    path: "",
    redirectTo:'login',
    pathMatch:'full'
  },
  {
    path: "login",
    loadChildren: '../app/content/pages/auth/login/login.module#LoginModule',
    canActivate: [LogoutGuardService]
  },
  {
    path: "SignUp",
    loadChildren: '../../src/app/content/pages/auth/sign-up/sign-up.module#SignUpModule',
    canActivate: [LogoutGuardService]
  },
  {
    path: "home",
    loadChildren: '../app/content/pages/pages.module#PagesModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'payrollReport',
    loadChildren: '../app/content/pages/components/payroll-reports/payroll-reports.module#PayrollReportsModule',
    canActivate: [AuthGuardStudioId]
  },
  {
    path: "resetPassword",
    loadChildren: '../app/content/pages/components/reset/reset.module#ResetModule',
    canActivate: [LogoutGuardService]
  },
  {
    path: "notFound",
    loadChildren: "../app/content/pages/components/page-not-found/page-not-found.module#PageNotFoundModule"
  },
  {
    path: "mobilePage",
    loadChildren: "./content/pages/components/mobile-page-error/mobile-page-error.module#MobilePageErrorModule"
  },
  {
    path: '**',
    redirectTo: 'notFound'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes), PagesModule,LoginModule],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }