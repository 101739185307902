import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { AsideMenuComponent } from './aside-menu/aside-menu.component';
import { RouterModule } from '@angular/router';
import { ModalComponent } from './modal/modal.component';
import { TwoDigitDecimaNumberDirective } from '../pages/shared/directives/decimal.directive';


@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  declarations: [HeaderComponent,AsideMenuComponent, ModalComponent, TwoDigitDecimaNumberDirective],
  exports:[HeaderComponent,AsideMenuComponent,ModalComponent, TwoDigitDecimaNumberDirective]
})
export class LayoutModule { }
