import { Injectable } from '@angular/core';
import { RequestOptions, ResponseContentType, Http, Headers } from '@angular/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class DownloadPdfService {
  constructor(private http: Http) { }

  // Download File
  downloadFile(start_date, end_date, membership_status, id): Observable<Blob> {
    let headers = new Headers();
    headers.append('Authorization', "Bearer " + localStorage.getItem('GreenliteAdminToken'));
    headers.append("apiType", "2")

    let options = new RequestOptions({ responseType: ResponseContentType.Blob, headers: headers });

    return this.http.get(environment.BASE_URL + `studio_waiver_report?start_date=${start_date}&end_date=${end_date}&membership_status=${membership_status}&client_id=${id}`, options)
      .pipe(map(res => res.blob()))
  }

  downloadPDF(start_date, end_date, membership_status, id): any {
    let headers = new Headers();
    let url = environment.BASE_URL + `studio_waiver_report?start_date=${start_date}&end_date=${end_date}&membership_status=${membership_status}&client_id=${id}`;

    headers.append('Authorization', "Bearer " + localStorage.getItem('GreenliteAdminToken'));
    headers.append("apiType", "2");
    headers.append("Content-Type", "application/json")
    
    let options = new RequestOptions({ responseType: ResponseContentType.Blob, headers: headers })

    return this.http.get(url, options).pipe(map(  
      (res) => {
        return new Blob([res.blob()])
      }))
    }
}

