import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appPhoneNumberFormat]'
})
export class PhoneNumberFormatDirective {

  constructor() { }
  
  @HostListener('input', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    
    const input = event.target as HTMLInputElement;

    let trimmed = input.value.replace(/[^\d]/g,'');
    if (trimmed.length > 12) {
      trimmed = trimmed.substr(0, 10);
    } else {
      input.value = trimmed.substring(0,3) + "-" +
      trimmed.substring(3, 6) + "-" + trimmed.substring(6);
    }

    // let numbers = [];
    // for (let i = 0; i < trimmed.length; i += 4) {
    //   numbers.push(trimmed.substr(i, 4));
    // }

    // input.value = numbers.join(' ');
  }

}
