import { Injectable } from "@angular/core";
@Injectable()
export class DialogService {

  confirm(message?: string) {
      const confirmation = window.confirm(message || ' You have unsaved changes. Are you sure want to leave this page?');
      if(confirmation) {
      } else {
        return false;
      }
    };

  } 