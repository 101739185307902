import { Injectable } from '@angular/core';
import { CanActivate,Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LogoutGuardService implements CanActivate {
  constructor(private _route:Router){  }
  canActivate(){
    if(localStorage.getItem('GreenliteAdminToken'))
    {
      this._route.navigate(['/studioSetup']);
      return false;
    }
    else
    {
    return true;
    }
  }
}