import { Component, OnInit, } from '@angular/core';
import { ActivatedRoute ,Router } from "@angular/router";
import { AuthenticationService } from '../../../../core/auth/authorisationService';
import { environment } from "../../../../../environments/environment";
import { ToastrService } from 'ngx-toastr';

declare var $;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  model: any = {};
  public pwdtype: any = "password";
  eyeclass: any = "far fa-eye-slash";
  public my_response: object;
  public resetmail: any;
  constructor(private route: ActivatedRoute ,private _router: Router, public _authService: AuthenticationService, private toastr: ToastrService) { }

  ngOnInit() {
  }
  
  showPassword() {
    if (this.pwdtype == "password") {
      this.pwdtype = "text";
      this.eyeclass = "far fa-eye";
    }
    else {
      this.pwdtype = "password";
      this.eyeclass = "far fa-eye-slash"
    }
  }

  navigateToDashboard() {
    this._authService.login(environment.BASE_URL + "login", this.model.email, this.model.password).subscribe((response: any) => {
      this.my_response = response.result;
      if(response.status==1)  { 
      localStorage.setItem('GreenliteAdminToken', this.my_response['token']);
      this._router.navigate(['/home']);
      }
    }, (err) => { 
      this.toastr.error(err.error.message, 'Error');
    })
  }

  ForgotPassword() {
    this._authService.forgotPassword(environment.BASE_URL + "password/create", this.resetmail).subscribe((response: any) => {
     
      this.my_response =response.result;
      if (response.status == 1) {
        this.toastr.success(response.result, '', {
          timeOut: 3000
        });
        this.resetmail = "";
        $("#forgetModal").modal('hide')
      }
      }, (err) => {  
        this.resetmail = "";
      })
  }
}
