import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  
  constructor(private _route:Router,private auth:AuthGuard){  }

  canActivate()  {

    if(!localStorage.getItem('GreenliteAdminToken'))
    {
      this._route.navigateByUrl('/login');
      return false;
    }
    else
    {
    return true;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthGuardStudioId implements CanActivate {
  
  constructor(private _route:Router,private auth:AuthGuardStudioId){  }

  canActivate()  {
    if(localStorage.getItem('studio_id') == 'null') {
        this._route.navigateByUrl('/');
        return false;
    } else {
      return true;
    }
  }
}