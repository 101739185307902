import { Component, OnInit } from '@angular/core';
import { of as observableOf, Subject } from 'rxjs';
import { delay } from 'rxjs/operators';
import { CommonMethodsService } from '../../pages/components/services/common-methods.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  public display: boolean = false;
  navigateAwaySelection$: Subject<boolean> = new Subject<boolean>();
  currentStatus: boolean = false;
  constructor(
    private _commonMethodsService: CommonMethodsService
  ) { }

  ngOnInit() {
    this._commonMethodsService.retailUnsavedModalStatus.subscribe(res => this.currentStatus = res);
  }

  // Function to show modal content
  public show() {
    this.display = true;
  }

  // Function to hide modal content
  public hide() {
    this.display = false;
  }

  public hideWithDelay(delayTime?: number) {
    observableOf(null).pipe(delay(delayTime ? delayTime : 2000)).subscribe(() => {
      this.display = false;
    });
  }
}
