import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonMethodsService } from '../../pages/components/services/common-methods.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  message: any
  constructor(private _router: Router, private toastr: ToastrService, private _commonMethodsService: CommonMethodsService) { }

  ngOnInit() {
    this._commonMethodsService.retailUnsavedModalStatus.subscribe(message => this.message = message)
  }

  // Logout of the App
  logout() {
    let token = localStorage.getItem("GreenliteAdminToken")
    if (token) {
      localStorage.clear();
      this._router.navigateByUrl('/login');
      this.toastr.success("Logged Out Successfully");
    } else {
      this._router.navigate(['/home']);
    }
  }
}
