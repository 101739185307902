import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CommonMethodsService {
  private messageSource = new BehaviorSubject('');
  private _listners = new Subject<any>();
  currentMessage = this.messageSource.asObservable();
  
  private _listenChange = new BehaviorSubject(false);
  currentChange = this._listenChange.asObservable();

  private retailUnsavedChanges = new BehaviorSubject(false); // Tracks if retail confirmUnsavedChanges modal is open or not
  retailUnsavedModalStatus = this.retailUnsavedChanges.asObservable();

  constructor() { }

  changeMe(change: boolean) {
    this._listenChange.next(change);
  }

  whyChange(): Observable<any> {
    return this._listenChange.asObservable();
  }

  // Here we change the status of the observable which tracks if the modal is open or not
  changeRetailUnsavedModalStatus(change: boolean) {
    this.retailUnsavedChanges.next(change)
  }

  changeMessage(message: string) {
    this.messageSource.next(message);
  }

  listen(): Observable<any> {
    return this._listners.asObservable();
  }

  filter(filterBy: string) {
    this._listners.next(filterBy);
  }

  // Converting Time
  setDateWithUTCTime(convertedDate) {

    convertedDate = new Date(convertedDate.setHours(0));
    convertedDate = new Date(convertedDate.setMinutes(0));
    convertedDate = new Date(convertedDate.setSeconds(0));
    return convertedDate;
  }

  // Utc Date Time
  // Convert UTC Date Time to Local Date Time
  // FORMAT: Like (new Date())
  getTimeDate(date, time) {
    let purchaseDate;
    let todayDate = new Date();
    if (date) {
      let splitDateArray = date.split('-');
      if (splitDateArray.length) {
        purchaseDate = new Date(todayDate.setDate(splitDateArray[2]));
        purchaseDate = new Date(purchaseDate.setMonth(splitDateArray[1] - 1));
        purchaseDate = new Date(purchaseDate.setFullYear(splitDateArray[0]));
      }
    }
    if (time) {
      let splitTimeArray = time.split(':');
      purchaseDate = new Date(purchaseDate.setHours(splitTimeArray[0]));
      purchaseDate = new Date(purchaseDate.setMinutes(splitTimeArray[1]));
      purchaseDate = new Date(purchaseDate.setSeconds(splitTimeArray[2]));
    }
    return purchaseDate;
  }

  // Convert Date
  convertDate(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  convertLocalDateToUTC(date, time) {
    return moment(`${date} ${time} DD-MM-YYYY HH:mm`).utc();
  }

  // Convert Time
  // FORMAT: HH: MM
  convertTime(time) { 
    let hours = time.getHours() > 9 ? time.getHours() : '0' + time.getHours();
    let minutes = time.getMinutes() > 9 ? time.getMinutes() : '0' + time.getMinutes();

    return hours + ':' + minutes;
  }

  convertTimePayroll(time) {
    let hours = time.getHours() > 9 ? time.getHours() : '0' + time.getHours();
    let minutes = time.getMinutes() > 9 ? time.getMinutes() : '0' + time.getMinutes();
    let seconds = time.getSeconds() > 9 ? time.getSeconds() : '0' + time.getSeconds();
    return hours + ':' + minutes + ':' + seconds;
  }

  // Local Time to UTC
  // FORMAT: HH: MM
  convertLocalTimeToUTC(time) {
    let timeToDateTime = (new Date(time));
    let utcTime = new Date(timeToDateTime.getTime() + (timeToDateTime.getTimezoneOffset() * 60000))
    let newHours = utcTime.getHours() > 9 ? utcTime.getHours() : '0' + utcTime.getHours();
    let newMinutes = utcTime.getMinutes() > 9 ? utcTime.getMinutes() : '0' + utcTime.getMinutes();

    return newHours + ':' + newMinutes;
  }

  // Utc date to local
  convertDateTolocal(date, time) {
    // let utcDateTime = moment.utc(`${date} ${(time).substring(5, 0)}`, "YYYY-MM-DD")
    let utcDateTime = moment.utc(`${date} ${(time).substring(5, 0)}`).format()
    var localDateTime = moment(utcDateTime).local().format('YYYY-MM-DD');

    return localDateTime;

  }

  // UTC to Local Time
  UtcToLocal(date, time) {
    let utcDateTime = moment.utc(`${date} ${(time).substring(5, 0)}`, "YYYY-MM-DD HH:mm")
    var localDateTime = utcDateTime.local().format('YYYY-MM-DD h:mm A');

    let dateString = localDateTime.substr(0, localDateTime.indexOf(' '));
    let timeIn24HourFormat = moment(localDateTime.substr(localDateTime.indexOf(' ') + 1), ["h:mm:ss A"]).format("HH:mm:ss");

    return this.convertTimeToDate(dateString, (timeIn24HourFormat));
  }

  // Convert Time to Date 
  convertTimeToDate(dateForTime, timeToBeConverted) {
    dateForTime = new Date();
    dateForTime.getHours(dateForTime.setHours(timeToBeConverted.split(':')[0]));
    dateForTime.getMinutes(dateForTime.setMinutes(timeToBeConverted.split(':')[1]));
    dateForTime.getSeconds(dateForTime.setSeconds(timeToBeConverted.split(':')[2]));

    return dateForTime;
  }

  // Functions which works
  convertUTC(date, time) {
    let d1 = moment(new Date(date)).format('YYYY-MM-DD');
    let d2 = new Date(d1);
    let x1 = new Date(d2.getFullYear(), d2.getMonth(), d2.getDate(), time.getHours(), time.getMinutes(), time.getSeconds(), time.getMilliseconds());

    let utcDateTime = new Date(x1.getTime() + (x1.getTimezoneOffset() * 60000));
    let z2 = moment(new Date(utcDateTime)).format('YYYY-MM-DD');

    return z2;
  }

  // Functions which works
  convertUTCLocal(date, time) {
    let d1 = new Date(date);
    let d2 = this.convertTimeToDate(d1, time);

    let x1 = new Date(d1.getFullYear(), d1.getMonth(), d1.getDate(), d2.getHours(), d2.getMinutes(), d2.getSeconds(), d2.getMilliseconds());

    let utcDateTime = new Date(x1.getTime() - (x1.getTimezoneOffset() * 60000));
    let z2 = moment(new Date(utcDateTime)).format('YYYY-MM-DD');

    return z2;
  }

  // Convert Time to date in case of payroll Reports
  convertTimeToDateNew(dateForTime, timeToBeConverted) {
    dateForTime = new Date();
    dateForTime.getHours(dateForTime.setHours(timeToBeConverted.split(':')[0]));
    dateForTime.getMinutes(dateForTime.setMinutes(timeToBeConverted.split(':')[1]));

    return dateForTime;
  }

  // Converts First Alphabet into Capital
  toCapital(str) {
    return str.replace(/\b\w/g, l => l.toUpperCase())
  }

  // Format Phone Number
  formatPhoneNumber(input) {
    // Strip non-numeric characters
    let format = 'XXX-XXX-XXXX';
    if (input.length > 0) {
      var digits = input.replace(/\D/g, '');

      // Replace each "X" with the next digit
      var count = 0;
      return format.replace(/X/g, function () {
        return digits.charAt(count++);
      });

    } else return '';
  }

  // Truncate string size if length more than 30
  truncateString(str) {
    let length = 30;
    let ending = '...';
    if (str && str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  }

  // Convert to FullName with Capital
  convertFullName(elem) {
    if (elem.middle_initial == null && elem.last_name !== null) {
      elem['fullName'] = this.toCapital(`${elem.first_name} ${elem.last_name}`);
    } else if (elem.last_name == null) {
      elem['fullName'] = this.toCapital(`${elem.first_name}`);
    } else {
      elem['fullName'] = this.toCapital(`${elem.first_name} ${elem.middle_initial} ${elem.last_name}`);
    }
  }

  // Convert HH:mm to Date Object
  toDateWithOutTimeZone(date) {
    let tempTime = date.split(":");
    let dt = new Date();
    dt.setHours(tempTime[0]);
    dt.setMinutes(tempTime[1]);
    return dt;
  }

  attendaceDateFormat(dateTime) {
    let tempTime = dateTime.split(":");
    let dt = new Date();
    dt.setHours(tempTime[0]);
    dt.setMinutes(tempTime[1]);
    let formattedTime = moment(dt).format('h:mm A');
    return formattedTime;
  }

  // Function to removes intial zeroes infront for integer
  getInteger(data) {
    let data_index = data.toString().lastIndexOf(0) + 1;
    let data_value = data.toString().substr(data_index);
    return data_value
  }

  // Convert Time to Est Time Zone
  estTimeZone(date) {
    const dt = new Date(date);
    const newDate = new Date(dt.setTime(dt.getTime() + dt.getTimezoneOffset() * 60 * 1000));
    return newDate;
  }

}
