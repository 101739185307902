// import { Directive, ElementRef, HostListener } from '@angular/core';
// @Directive({
//   selector: '[appTwoDigitDecimaNumber]'
// })
// export class TwoDigitDecimaNumberDirective {
//   private regex: RegExp = new RegExp("^([0-9]{1,5})(([\.])([0-9]{1,2}))?$");
//   private specialKeys: Array<string> = ['.','Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
//   constructor(private el: ElementRef) {
//   }
//   @HostListener('keydown', ['$event'])
//   onKeyDown(event: KeyboardEvent) {
//     // Allow Backspace, tab, end, and home keys
//     if (this.specialKeys.indexOf(event.key) !== -1) {
//       return;
//     }
//     // const current: string = this.el.nativeElement.value; 
//     // const position = this.el.nativeElement.selectionStart;
//     // const next: string = [current.slice(0, position), event.key === '.' ? '.' : event.key, current.slice(position)].join('');

//     // if(!this.regex.test(next))
//     // {
//     //   event.preventDefault();
//     // }
//   }
// }


// jagans code

import { Directive, ElementRef, HostListener } from '@angular/core';
@Directive({
  selector: '[appTwoDigitDecimaNumber]'
})
export class TwoDigitDecimaNumberDirective {
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
  constructor(private el: ElementRef) {
  }
  @HostListener('keydown', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const allowedLengthBeforeDecimal = 5, allowedLengthAfterDecimal = 2;
    let isValidBeforeDec = true;
    let isValidAfterDec = true;
    const current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key === 'Decimal' ? '.' : event.key, current.slice(position)].join('');

    if (next.indexOf('.') !== -1) {
      const x = next.split('.');
      x[0].length <= allowedLengthBeforeDecimal ? isValidBeforeDec = true : isValidBeforeDec = false;
      x[1].length <= allowedLengthAfterDecimal ? isValidAfterDec = true : isValidAfterDec = false;

    } else {
      next.length <= allowedLengthBeforeDecimal ? isValidBeforeDec = true : isValidBeforeDec = false;
    }
    if (next && (!String(next).match(this.regex) || !isValidAfterDec || !isValidBeforeDec)) {
      event.preventDefault();
    }
  }
}