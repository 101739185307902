import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard, AuthGuardStudioId } from '../../core/auth/auth.guard';
import { PagesComponent } from './pages.component';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { LayoutModule } from '../layout/layout.module';
import { CanDeactivateGuard } from 'src/app/core/auth/can-deactivate.guard';
import { DialogService } from 'src/app/core/auth/dialog.service';
import { CommonMethodsService } from './components/services/common-methods.service';
import { DownloadExcelService } from './components/services/download-excel.service';
import { DownloadPdfService } from './components/services/download-pdf.service';
import { PhoneNumberFormatDirective } from './shared/directives/phone-number-format.directive';

const routes: Routes = [
  {
    "path": "",
    "component": PagesComponent,

    "children": [
      {
        path: "",
        redirectTo: 'studioSetup',
        pathMatch: 'full'
      },
      {
        path: 'studioSetup',
        loadChildren: '../pages/components/studio-setup-home/studio-setup-home.module#StudioSetupHomeModule',
        canActivate: [AuthGuard]
      },
      {
        "path": "familyGroups",
        loadChildren: './components/family-groups/family-groups.module#FamilyGroupsModule',
        canActivate: [AuthGuardStudioId]
      },
      {
        "path": "clientmanagement",
        loadChildren: './components/client-management/client-management.module#ClientManagementModule',
        canActivate: [AuthGuardStudioId]

      },
      {
        "path": "ClassTimeSetup",
        loadChildren: '../pages/components/class-time-setup/class-time-setup.module#ClassTimeSetupModule',
        canActivate: [AuthGuardStudioId]

      },
      {
        "path": "agreement",
        loadChildren: '../pages/shared/agreement/agreement.module#AgreementModule',
        canActivate: [AuthGuardStudioId]
      },
      {
        "path": "staffSetup",
        loadChildren: '../pages/components/staff-setup/staff-setup.module#StaffSetupModule',
        canActivate: [AuthGuardStudioId]
      },
      {
        path: "retailGiftSetup",
        loadChildren: './components/retail-gift-setup/retail-gift-setup.module#RetailGiftSetupModule',
        canActivate: [AuthGuardStudioId]
      },
      {
        path: "classesSetup",
        loadChildren: '../pages/components/classes-setup/classes-setup.module#ClassesSetupModule',
        canActivate: [AuthGuardStudioId]
      },
      {
        path: 'reports',
        loadChildren: '../pages/components/reports/reports.module#ReportsModule',
        canActivate: [AuthGuardStudioId]
      },
      {
        path: 'transactions',
        loadChildren: '../pages/components/daily-transactions/daily-transactions.module#DailyTransactionsModule',
        canActivate: [AuthGuardStudioId]
      },
      {
        path: 'help-support',
        loadChildren: '../pages/components/help-support/help-support.module#HelpSupportModule',
        canActivate: [AuthGuardStudioId]
      },
      {
        path: 'Credit-Card',
        loadChildren: '../pages/components/credit-card-apply/credit-card-apply.module#CreditCardApplyModule',
      },
      {
        path: 'waiver-policy',
        loadChildren: './components/waiver-policy/waiver-policy.module#WaiverPolicyModule',
      }
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    LayoutModule,
  ],

  declarations: [PagesComponent, PhoneNumberFormatDirective],
  providers: [CanDeactivateGuard, DialogService, CommonMethodsService, DownloadExcelService, DownloadPdfService],
  exports: [PhoneNumberFormatDirective]
})
export class PagesModule { }
