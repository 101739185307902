import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';

import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticationService } from "./core/auth/authorisationService";
import { HeadParInterceptor, DataService } from './core/interceptors/header-interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { ConfirmEqualValidatorDirective } from './core/directives/confirm-equal-validator.directive';
import { NgxSpinnerModule } from 'ngx-spinner';

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProgressBarModalComponent } from './progressbar-modal.component';
import { ClassesService } from './content/pages/components/class-time-setup/services/classes.service';

@NgModule({
  declarations: [
    AppComponent,
    ConfirmEqualValidatorDirective,
    ProgressBarModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      maxOpened: 1,
      autoDismiss: true,
      preventDuplicates: true
    }),
    NgxSpinnerModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeadParInterceptor,
      multi: true,

    },
    AuthenticationService, DataService, ClassesService, ConfirmEqualValidatorDirective
  ],

  bootstrap: [AppComponent],
  entryComponents: [ProgressBarModalComponent]
})
export class AppModule { }
