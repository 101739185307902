import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalComponent } from './../modal/modal.component';
import { Router } from '@angular/router';
import { DataService } from 'src/app/core/interceptors/header-interceptor';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-aside-menu',
  templateUrl: './aside-menu.component.html',
  styleUrls: ['./aside-menu.component.scss']
})
export class AsideMenuComponent implements OnInit {

  @ViewChild('model') model: ModalComponent;
  classTimeSetup: any;
  studioData: any;

  constructor(private _router: Router, private service: DataService) { }

  ngOnInit() { }

  onButtonClick(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    return false;
  }

  navigateToClassSetup() {
    if (localStorage.getItem('studio_id') == "null" || localStorage.getItem('linkEnable') == null) {
      this.model.show();
      this._router.navigate(['/home/studioSetup'])
    }
    else if (localStorage.getItem('studio_id') && localStorage.getItem('linkEnable') == '0') {
      this.model.show();
      this._router.navigate(["/home/Credit-Card"])
    }
  }

  navigateTOFacility() {
    if (localStorage.getItem('studio_id') == "null") {
      this.model.show();
      this._router.navigate(['/home/studioSetup'])
    }
  }

  public hide() {
    this.model.hide()
  }
}
